import './App.scss';
import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import LandingPage from './pages/LandingPage.js/LandingPage';

function App() {
  return (
    <div className="App">
      <CssBaseline/>
      <BrowserRouter>
      <Header/>
        <div className='Page'>
          <Routes>
            <Route path='/' exact element={<LandingPage/>} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
