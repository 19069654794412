import "./ContactMe.scss"
import { useState } from "react"
import { send } from "emailjs-com"


export default function ContactMe() {
    const [toSend, setToSend] = useState({
        first_name:"",
        last_name:"",
        message:"",
        reply_to:""
    });

    const handleChange = (e)=>{
        setToSend({...toSend, [e.target.name]:e.target.value})
    }

    const onSubmit = (e)=>{
        e.preventDefault();
        send(
            'service_5b77axq',
            'template_dh856pb',
            toSend,
            'JDk9o9-pqubWpgq4M'
        ).then((response)=>{
            console.log("Success", response.status,response.text)
        })
        .catch((err)=>{
            console.log("failed", err)
        })
    }
  return (
    <div className="contactme__container" id="contact">
        <h2 className="contactme__title">CONTACT</h2>
        <form className="contactme__form" onSubmit={onSubmit}>
            <input 
                className="contactme__form-input" 
                type="text" 
                name="first_name"
                value={toSend.first_name} 
                onChange={handleChange}
                placeholder="First Name"></input>
            <input 
                className="contactme__form-input" 
                type="text" 
                name="last_name"
                value={toSend.last_name}
                onChange={handleChange} 
                placeholder="Last Name"></input>
            <input 
                className="contactme__form-input" 
                type="email" 
                name="reply_to" 
                value={toSend.reply_to}
                onChange={handleChange}
                placeholder="Email"></input>
            <textarea 
                className="contactme__form-textarea" 
                name="message" 
                rows="6"
                value={toSend.message}
                onChange={handleChange} 
                placeholder="Message"></textarea>
            <button className="contactme__button" type="submit">Send</button>
        </form>
    </div>
  )
}
