import "./Footer.scss";
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import resume from "../../assets/resume/Bryan_Kwok_Fullstack_Resume.pdf"

export default function Footer() {
  return (
    <div className="footer__container">

        <div className='footer__icons'>
            <a href="https://www.linkedin.com/in/bryan-py-kwok/" target="_blank" rel="noreferrer" className='footer__icon'>
                <AiIcons.AiFillLinkedin />
                LinkedIn
            </a>
            <a href='https://github.com/Bryan-kwok' target="_blank" rel="noreferrer" className='footer__icon'>
                <AiIcons.AiFillGithub/>
                Github
            </a>
            <a href={resume} target="_blank" rel="noreferrer" className='footer__icon'>
                <HiIcons.HiDocumentDownload/>
                My Resume
            </a>
            <div className='footer__icon'><AiIcons.AiOutlineMail/>bryanpykwok@gmail.com</div>
            <div className='footer__icon'><AiIcons.AiFillPhone/>+1(647)-867-8518</div>
        </div>


    </div>
  )
}
