import "./WhoAmI.scss";
import icons from "../../assets/data/IconData"
import picture from "../../assets/images/image1.png"
export default function WhoAmI() {
  return (
    <div className="whoamI__container" id="about">
        <div className="whoamI__wrapper">
            <section className="whoamI__text">
                <h2 className="whoamI__title">Who am I?</h2>
                <h2 className="whoamI__catchphrase">It's Never Too Late</h2>
                <article className="whoamI__article">
                    &nbsp; &nbsp; &nbsp; is what I told myself, so I made a change. I am a Mechanical Engineer turned Full-Stack Web Developer. After discovering my passion for coding, 
                    I completed a bootcamp at Brainstation for Full-Stack Development and have been dedicated 
                    to pursuing this field ever since. 
                </article>
            </section>
            <img className="whoamI__image" src={picture} alt="whoamI"></img>
        </div>
        <div className="whoamI__icons-container" id="skillset">
            <h2 className="whoamI__icons-title">Tech Stack</h2>
            <ul className="whoamI__icons-slider">
                <li className="whoamI__icon"><img className="whoamI__icon-image" alt="icon" src={icons.htmlPNG}/>HTML</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image" alt="icon" src={icons.cssPNG}/>CSS</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image" alt="icon" src={icons.javascriptPNG}/>Javascript</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image"alt="icon" src={icons.reactPNG}/>ReactJS</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image"alt="icon" src={icons.nodePNG}/>NodeJS</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image"alt="icon" src={icons.mysqlPNG}/>MySQL</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image"alt="icon" src={icons.axiosPNG}/>Axios</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image"alt="icon" src={icons.expressPNG}/>ExpressJS</li>
                <li className="whoamI__icon"><img className="whoamI__icon-image"alt="icon" src={icons.reactHooksPNG}/>React Hooks</li>  
                <li className="whoamI__icon"><img className="whoamI__icon-image"alt="icon" src={icons.sassPNG}/>SASS</li>
            </ul>
        </div>
    </div>
  )
}
