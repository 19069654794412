import "./LandingPage.scss"
import headShot from "../../assets/images/headshot_circlecrop.png"
import * as FaIcons from 'react-icons/fa';
import WhoAmI from "../../components/WhoAmI/WhoAmI";
import Projects from "../../components/Projects/Projects";
import ContactMe from "../../components/ContactMe/ContactMe";
import Footer from "../../components/Footer/Footer";

export default function LandingPage() {
  return (
    <div className='landing__container' id="landing">
      <div className="landing__wrapper">
        <section className="landing__hero">
          <img src={headShot} className="landing__picture" alt="headshot"></img>
          <section className="landing__section">
            <div className="landing__texts">
              <h1 className='landing__text'>Hi, I'm <span className='landing__myname'>Bryan.</span></h1>
              <h2 className='landing__text'>I'm a full stack developer.</h2>
            </div>
            <div className="landing__navButtons">
              <a className="landing__links fill-on-hover" href="#skillset">Skills</a>
              <a className="landing__links" href="#projects">Projects</a>
              <a className="landing__links" href="#contact">Contact</a>
            </div>
          </section>
        </section>
        <button className="landing__button--cta"><a href="#about">Here I am! &nbsp; <FaIcons.FaArrowDown/></a></button>
      </div>
      <WhoAmI/>
      <Projects/>
      <ContactMe/>
      <Footer/>
    </div>
  )
}
