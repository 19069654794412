import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import "./Header.scss";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from "react-icons/ai";
import * as HiIcons from "react-icons/hi";
import { IconContext } from "react-icons";
import NavBar from '../NavBar/NavBar';
import resume from "../../assets/resume/Bryan_Kwok_Fullstack_Resume.pdf"

export default function Header() {
    const [sidebar, setSideBar] = useState(false)

    const showSideBar = ()=> setSideBar(!sidebar)
  return (
    <>
        <IconContext.Provider value={{color: "#8E8D8A"}}>
            <div className='header__container'>
                <Link to="#" className='header__menu'>
                    <FaIcons.FaBars onClick={showSideBar}/>
                </Link>
                <div className='xxx'>&nbsp;</div>
                <ul className='header__links'>
                    <li className='header__link'><a href="#landing">Home</a></li>
                    <li className='header__link'><a href="#about">About</a></li>
                    <li className='header__link'><a href="#skillset">Skillset</a></li>
                    <li className='header__link'><a href="#projects">Projects</a></li>
                    <li className='header__link'><a href="#contact">Contact</a></li>
                </ul>
                <div className='header__icons'>
                    <a href="https://www.linkedin.com/in/bryan-py-kwok/" target="_blank" rel="noreferrer" className='header__icon'>
                        <AiIcons.AiFillLinkedin/>
                    </a>
                    <a href='https://github.com/Bryan-kwok' target="_blank" rel="noreferrer" className='header__icon'>
                        <AiIcons.AiFillGithub/>
                    </a>
                    <a href={resume} download type="application/pdf" className='header__icon'>
                        <HiIcons.HiDocumentDownload/>
                    </a>
                </div>
            </div>
        </IconContext.Provider>

        <NavBar sidebar={sidebar} showSideBar={showSideBar} />
    </>
  )
}
