import "./Projects.scss"
import planeasy from "../../assets/images/PlanEasy.PNG"
import instockMobile from "../../assets/images/InStock_mobile.PNG"
import ffxiv from "../../assets/images/FFXIVGatheringHub.PNG"


export default function Projects() {
    
  return (
    <div className="projects__container" id="projects">
        <h2 className="projects__title">PROJECTS</h2>

        <div className="projects__block">
            <section className="projects__divider">
                <h3 className="projects__name">PlanEasy</h3>
                <ul className="projects__techstack">
                    <li className="projects__tech">React</li>
                    <li className="projects__tech">MaterialUI</li>
                    <li className="projects__tech">Javascript</li>
                    <li className="projects__tech">Sass</li>
                    <li className="projects__tech">React Hooks</li>
                </ul>
                <article className="projects__description">
                    Ever wanted to plan a nice get-together with some friends, but it always
                    seems impossible because no one can decide on a date and time? Well here is
                    PlanEasy! An interactive, real time filtering tool to help friends find
                    the right time and date for a meet up! Simply put in the days and times that
                    work for you, and the computer will show you all the dates that work, no more
                    guessing and double checking for hours on end.
                </article>
                <div className="projects__links">
                    <button className="projects__link"><a href="https://bryan-kwok.github.io/PlanEasy/" target="_blank" rel="noreferrer">See Live</a></button>
                    <button className="projects__link"><a href="https://github.com/Bryan-kwok/PlanEasy" target="_blank" rel="noreferrer">Source Code</a></button>
                </div>
            </section>
            <img src={planeasy} className="projects__image" alt="planeasy"></img>
        </div>
        
        <div className="projects__block">
            <section className="projects__divider">
                <h3 className="projects__name">InStock</h3>
                <ul className="projects__techstack">
                    <li className="projects__tech">React</li>
                    <li className="projects__tech">Javascript</li>
                    <li className="projects__tech">Sass</li>
                    <li className="projects__tech">Class Components</li>
                    <li className="projects__tech">Node/Express</li>
                    <li className="projects__tech">Axios</li>
                </ul>
                <article className="projects__description">
                    InStock is a full-stack React application brought to life by an Agile team of
                    developers, to deliver a mock Inventory Management System for a Fortune 500 Client.
                    The goal is to deliver a time-sensitive, full stack application with a modern React front-end
                    communicating with a robust Express back-end. This project was managed with a JIRA Scrum board
                    and a design tool called Figma to deliver the style guides and mockups.
                </article>
                <p className="projects__disclaimer">Note: The server is being hosted on Render, please give it a few seconds to load, and refresh after.</p>
                <div className="projects__links">
                    <button className="projects__link"><a href="https://bryan-kwok.github.io/InStock-Client/" target="_blank" rel="noreferrer">See Live</a></button>
                    <button className="projects__link"><a href="https://github.com/Bryan-kwok/InStock-Client" target="_blank" rel="noreferrer">Source Code</a></button>
                </div>
            </section>
            <img src={instockMobile} className="projects__image" alt="instock"></img>
        </div>

        <div className="projects__block">
            <section className="projects__divider">
                <h3 className="projects__name">FFXIV Gathering Hub</h3>
                <ul className="projects__techstack">
                    <li className="projects__tech">React</li>
                    <li className="projects__tech">Javascript</li>
                    <li className="projects__tech">JSON</li>
                    <li className="projects__tech">Class Components</li>
                    <li className="projects__tech">Node/Express</li>
                    <li className="projects__tech">Axios</li>
                    <li className="projects__tech">RESTful API</li>
                </ul>
                <article className="projects__description">
                    FFXIV Gathering Hub is a passion project stemming from an online game that I adore.
                    The purpose is to create an efficient method which the community could filter a
                    large list of objectives, and gather like-minded people to form a party with. This project
                    is well within its early stages, and is a sign of what's to come.
                </article>
                <p className="projects__disclaimer">Note: The server is being hosted on Render, please give it a few seconds to load, and refresh after.</p>
                <div className="projects__links">
                    <button className="projects__link"><a href="https://bryan-kwok.github.io/FFXIV_GatheringHub_Client/" target="_blank" rel="noreferrer">See Live</a></button>
                    <button className="projects__link"><a href="https://github.com/Bryan-kwok/FFXIV_GatheringHub_Client" target="_blank" rel="noreferrer">Source Code</a></button>
                </div>
            </section>
            <img src={ffxiv} className="projects__image ffxiv" alt="ffxiv"></img>
        </div>
    </div>
  )
}
