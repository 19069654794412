import "./NavBar.scss";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { SideBarData } from "../../assets/data/SideBarData";
import { IconContext } from "react-icons";

export default function NavBar({sidebar,showSideBar}) {
    

  return (
    <nav className={sidebar? "navbar__container active":"navbar__container"}>
        <IconContext.Provider value={{color: "#8E8D8A"}}>
            <ul className="navbar__items" onClick={showSideBar}>
                <li className="navbar__toggle">
                    <Link to="#" className="navbar__item--bar">
                        <AiIcons.AiOutlineClose/>
                    </Link>
                </li>
                {SideBarData.map((item,index)=>{
                    return(
                        <li key={index} className={item.cName}>
                            <a href={item.path}>
                                {item.icon}
                                <span className="navbar__span">{item.title}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </IconContext.Provider>
    </nav>
  )
}
